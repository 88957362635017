<template>
  <div>
    <h3>当前客户端版本</h3>
    <span>{{clientVersion}},更新于{{clientVersionDate}}</span>
    <h3>当前服务器版本</h3>
    <span>{{serverVersion}},更新于{{serverVersionDate}}</span>
  </div>
</template>

<script>
export default {
  name: "SystemVersion",
  data(){
    return{
      serverVersion: '---',
      serverVersionDate: '---',
      clientVersion: process.env.VUE_APP_VERSION,
      clientVersionDate: process.env.VUE_APP_VERSION_DATE
    }
  },
  created() {
    this.$axios.get('/resource/sysversion').then(res => {
      let info = null
      if(res.data.data){
        info = res.data.data
      }else{
        info = res.data
      }
      this.serverVersion = info.version
      this.serverVersionDate = info.date
    })
  }
}
</script>

<style scoped>

</style>